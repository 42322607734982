import React from "react";
import cn from "classnames";
import styles from "./Post.module.sass";

const Post = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h1 className={cn("h1", styles.title)}>Terms of Service</h1>
        </div>

        <h3>1 Scope of application</h3>
        <br />
        <p>
          1.1 GUU Labs SRL, e-mail address cristian@shopmobilepro.com, shall
          hereinafter be referred to as Shop Mobile Pro (any reference made
          herein to 'us', 'we' or 'our' shall be interpreted accordingly), and
          the contractual partner shall hereinafter be referred to as the
          Customer (any reference made herein to 'you' and 'your' shall likewise
          be interpreted accordingly).
        </p>
        <br />
        <p>
          1.2 These General Terms and Conditions (GTC) apply to all present and
          future transactions concluded via the Shop Mobile Pro online shop
          (www.shopmobilepro.com) or the respective mobile application,
          hereinafter referred to as Shop Mobile Pro.
        </p>
        <br />
        <p>
          1.3 By extending an offer to us, within the meaning provided in
          Section 4 below, you declare to agree to these GTCs. Any diverging
          terms and conditions applicable at your end shall not be binding on
          our end. This shall also include cases in which we do not expressly
          reject such diverging terms and conditions. Any terms deviating from
          these GTC shall be considered effective only if such terms are
          confirmed by us in writing. Our actions of contract fulfilment shall
          not be deemed as confirmation.
        </p>
        <br />
        <h3>2 Contract language</h3>
        <br />
        <p>
          2.1 The contract language is English. Any translations of these GTC
          shall be for convenience purposes only. In case of any discrepancies
          between the English version and any other language version, the
          English version shall prevail.
        </p>
        <br />
        <h3>3 Conclusion of contract</h3>
        <br />
        <p>
          3.1 The presentation of products in the Shop Mobile Pro online shop
          shall not constitute a legally binding offer, but rather an invitation
          to place an order. Errors excepted.
        </p>
        <br />
        <p>
          3.2 By clicking on the 'Buy Now' button, you are placing a binding
          order for the products listed on the order page. The contract shall be
          deemed concluded upon receipt of our order confirmation by e-mail.
        </p>
        <br />
        <h3>4 Offer and acceptance</h3>
        <br />
        <p>
          4.1 The prices, price offers and descriptions provided in Shop Mobile
          Pro do not constitute a binding offer and may be withdrawn or amended
          by us, at any time prior to the express acceptance of your offer.
        </p>
        <br />
        <p>
          4.2 Any and all offers made by you to purchase products and/or
          services from us, shall require our subsequent acceptance. We are not
          obliged to accept your offer. A contract shall be concluded solely
          with our express or silent acceptance of your offer, in particular by
          dispatch of the goods ordered by you.
        </p>
        <br />
        <h3>5 Prices and payment terms</h3>
        <br />
        <p>
          5.1 The price of each individual product is as stated, and unless
          otherwise and individually agreed upon in detail, the following
          provisions shall apply:
        </p>
        <br />
        <p>
          5.2 Prices are stated in EUROS or other currency, plus turnover or
          value added tax, as applicable. Unless otherwise stated, the prices do
          not include shipping and transport costs. Shipping and transport costs
          are stated separately and are to be borne by you. In the event that
          the conclusion or performance of a contract gives rise to taxes, bank
          charges, fees, customs or levies, these shall be borne by the
          Customer.
        </p>
        <br />
        <p>
          5.3 The invoice total shall be due upon your order. In the event of an
          order consisting of several partial product deliveries under Section
          6.3, we reserve the right to charge each partial delivery under a
          partial invoice.
        </p>
        <br />
        <p>
          5.4 You shall not be entitled to setoff claims unless a counterclaim
          has been confirmed with final effect issued by a court of law or
          recognized by us.
        </p>
        <br />
        <h3>6 Delivery and transfer of risk</h3>
        <br />
        <p>
          6.1 Unless otherwise agreed upon, delivery shall be made to the
          delivery address specified by you. We reserve the right to make
          partial deliveries.
        </p>
        <br />
        <p>
          6.2 The risk of accidental loss or accidental deterioration of the
          goods shall pass to you upon delivery of the goods to the carrier.
        </p>
        <br />
        <p>
          6.3 In the event that we are unable to deliver the goods ordered by
          you, for reasons beyond our control, we shall be entitled to withdraw
          from the contract. In such case, we shall inform you without delay and
          reimburse any payments already made by you.
        </p>
        <br />
        <h3>7 Force Majeure</h3>
        <br />
        <p>
          Both parties will not be liable for any default or delay in the
          performance of their respective non-monetary obligations, to the
          extent that such default or delay is caused, directly or indirectly,
          by fire, flood, earthquake, explosions, elements of nature, acts of
          God, acts or regulations of government bodies, nuclear, chemical or
          biological contamination not caused by such Party, court orders
          arising out of circumstances other than a breach of this Agreement by
          the affected Party, acts of war, terrorism, riots, civil disorders,
          rebellions or revolutions, strikes, lockouts or labor difficulties,
          epidemics or by any other event or circumstance that is beyond the
          reasonable control of you or us, whichever is the entity unable to
          perform (the Nonperforming Party). Such event or circumstance giving
          rise to the default or delay is a Force Majeure Event.
        </p>
        <br />
        <p>
          The parties acknowledge and agree that COVID-19 is such Force Majeure
          Event, and it is not possible to foresee its duration, impact or
          extent (including measures and recommendations that may be put in
          place by regulators). As such, where our obligations are not
          performed, affected, and/or delayed and that is attributable to
          COVID-19, notwithstanding any other provision in the agreement, we
          will not be responsible for such delay, non-performance or failure. We
          will both act reasonable and meet without delay, discuss the affected
          obligations, potential work arounds and related issues in good faith
          and will document any agreed changes to the Agreement.
        </p>
        <br />
        <p>
          The Nonperforming Party will be excused from any further performance
          of the obligations affected by such Force Majeure Event for as long as
          such Force Majeure Event continues, and the Nonperforming Party
          continues to use commercially reasonable efforts to recommence
          performance.
        </p>
        <br />
        <h3>8 Retention of title</h3>
        <br />
        <p>
          8.1 Property title to the product(s) shall be passed on to you only
          after the full payment of the price, including any interest or other
          amounts relating to the product(s) have been executed. Until such
          time, there is no authorization to dispose of the product(s). You must
          store the product(s) and keep related records in such a way as to
          enable us to distinguish between product(s) paid for in full and
          product(s) for which payment is outstanding. Chattel pledges or
          mortgages shall not be permissible.
        </p>
        <br />
        <p>
          8.2 You must inform us in writing, without delay, of any related
          third-party intervention.
        </p>
        <br />
        <p>
          8.3 We reserve the right to re-possess and re-sell our product(s) in
          the event of default or in the event that the conditions for
          rescission are fulfilled. Repossession shall only entail rescission of
          the contract upon our express declaration to this effect. In the event
          of product(s) return, we shall be entitled to bill you for any
          transport and handling expenses incurred.
        </p>
        <br />
        <h3>9 Obligation to inspect and complain</h3>
        <br />
        <p>
          You are obliged to inspect the product(s) and ensure that they are in
          faultless condition, conform to the description and are complete. You
          may only assert claims for defective product(s) or incomplete delivery
          in the following cases: if you send us a written and specific
          complaint for the defect/s or incomplete delivery without delay, and
          in any event no later than 8 days from the receipt of the product(s).
        </p>
        <br />
        <h3>10 Liability</h3>
        <br />
        <p>
          10.1 We shall only assume liability for damage due to willful or
          grossly negligent failure to fulfil obligations on our part or on the
          part of our vicarious agents. Claims for damages, if any, shall become
          statute-barred 6 months from the date you became aware of the damage
          and the party at fault.
        </p>
        <br />
        <p>
          10.2 We shall not be liable for any indirect or consequential damages
          or loss of profit.
        </p>

        <br />
        <p>
          10.3 Based on the current state of art, data communication via
          Internet cannot be guaranteed to be error-free and/or available at all
          times. We are not liable for the constant and uninterrupted
          availability of our online trading system.
        </p>
        <br />
        <p>
          10.4 You are committed to all actions that can be expected of you to
          prevent and reduce damage.
        </p>
        <br />
        <h3>11 Warranty</h3>
        <br />
        <p>
          11.1 We shall provide warranty for defects in the product(s) in
          accordance with the statutory provisions, subject to the following
          provisions:
        </p>
        <br />
        <p>
          11.2 You must inspect the product(s) immediately upon receipt and
          notify us of any defects in writing without delay. In the event of
          hidden defects, you must notify us in writing without delay upon
          discovery of the defect. The notification must be made in writing and
          must specify the defect in detail.
        </p>
        <br />
        <p>
          11.3 You shall not be entitled to assert any warranty claims if you
          have not fulfilled your payment obligations in full.
        </p>
        <br />
        <p>
          11.4 In the event of a defect, we shall be entitled to choose between
          rectification and replacement delivery. If rectification or
          replacement delivery fails, you shall be entitled to choose between
          rescission of the contract and reduction of the purchase price.
        </p>
        <br />
        <p>
          11.5 We shall not be liable for any defects caused by improper use,
          faulty assembly or commissioning by you or third parties, natural wear
          and tear, faulty or negligent handling, or chemical, electro chemical
          or electrical influences, unless such defects are caused by our fault.
        </p>
        <br />
        <p>
          11.6 The warranty period shall be 12 months from the date of delivery.
        </p>
        <br />
        <h3>12 Permits, customs duties and export</h3>
        <br />
        <p>
          12.1 Should the purchase, transport or use of product(s) be subject to
          a permit or license from a government or other authority, it shall be
          incumbent on you to obtain such permit or license at your expense and
          to furnish us with proof thereof, upon request. If you fail to obtain
          such permit or license, this shall not entitle you to retain or delay
          payment for ordered product(s). You shall bear any and all costs and
          expenses arising from the failure to obtain the required permit or
          license.
        </p>
        <br />
        <p>
          12.2 Products, imported into certain countries, may be subject to
          customs duties. Upon the arrival of the product(s) at the destination
          you have designated for delivery, customs duties, import duties and
          taxes may be imposed upon you. All such additional costs for customs
          clearance and import duties or taxes shall be borne by you.
        </p>
        <br />
        <h3>13 Notices</h3>
        <br />
        <p>
          13.1 Unless otherwise stated in these GTC, any and all notices and
          other communication concerning these contractual relations may be
          served by post (sufficient postage prepaid) or e-mail at the address
          stated in Section 1.1 and the address you most recently communicated
          to us, in writing.
        </p>
        <br />
        <p>
          13.2 For as long as the contractual legal transaction has not been
          fully performed by both parties, you shall be obliged to communicate
          to us any changes to your business address. Should you fail to
          communicate a change of address, any notifications shall be deemed
          delivered, if they have been sent to the address most recently
          communicated.
        </p>
        <br />
        <h3>14 Personal Information and data protection</h3>
        <br />
        <p>
          We shall process or use your personal data solely to the extent
          permitted by law and shall safeguard them from unauthorized access.
          The terms of our Privacy Policy apply.
        </p>
        <br />
        <h3>15 Final provisions</h3>
        <br />
        <p>
          15.1 The transfer of rights and obligations from the concluded
          contractual relationship requires our prior written consent.
        </p>
        <br />
        <p>
          15.2 Should a party waive or temporarily omit to enforce its rights
          under these General Terms and Conditions or if it grants the other
          party a grace period, the rights of the party first mentioned shall,
          in any event, remain unaffected. A waiver of asserting one's rights in
          view of a breach of these General Terms and Conditions cannot be
          construed as a waiver of asserting one's rights in view of subsequent
          breaches of these GTC.
        </p>
        <br />
        <p>
          15.3 Should any provision of these GTC be or become invalid, the
          validity of the remaining provisions shall not be affected thereby.
          The invalid provision shall be replaced by a valid provision that
          comes closest to the economic purpose of the invalid provision. The
          same shall apply in the event of any omissions in these GTC.
        </p>
        <br />
        <p>
          15.4 Notwithstanding the foregoing choice of exclusive forum, we shall
          have the right to file any suit or seek interim relief before the
          courts having local and factual jurisdiction over you.
        </p>
        <br />
        <p>
          15.5 These GTC shall be governed by and construed in accordance with
          the laws of Romania, without regard to its conflict of laws rules.
        </p>
        <br />
      </div>
    </div>
  );
};

export default Post;
