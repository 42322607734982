import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Book.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";

const items = [
  {
    title: "For individuals",
    color: "#39b159",
    images: "/images/content/user.svg",
    alt: "user",
    content: "If you never had an online store before, this is for you to explore how it can look like on mobile.",
  },
  {
    title: "Advanced stores",
    color: "#5338DC",
    images: "/images/content/medal-1.svg",
    alt: "medal",
    content: "You already have an online store, and you want to go mobile.",
  },
  {
    title: "Pro level",
    color: "#ef4670",
    images: "/images/content/lightning.svg",
    alt: "lightning",
    content: "You have a large store with many products and you want a custom version of the app with a lot of extra features and customizations.",
  },
];

const Book = () => {
  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/app-my-account-and-cart@2x.jpg 2x"
              src="/images/content/app-my-account-and-cart.jpg"
              alt="App my account and cart"
            />
          </div>
          {/* <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            <img
              srcSet="/images/content/chat@2x.png 2x"
              src="/images/content/chat.png"
              alt="Chat"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            <img
              srcSet="/images/content/shopping_bag@2x.png 2x"
              src="/images/content/shopping_bag.png"
              alt="Shopping bag"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            <img
              srcSet="/images/content/trolley@2x.png 2x"
              src="/images/content/dumbbells.png"
              alt="Dumbbells"
            />
          </ScrollParallax> */}
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Is it for me?</h2>
          <div className={styles.info}>
            The best way to find out is to try it. The mobile app can scale to any store size, and it's designed to be customized in any way you want. Extra features can be added on demand.
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <img src={x.images} alt={x.alt} />
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className={styles.note}>
            <strong className={styles.green}>10% Discount</strong> if you’re
            already using the{" "}
            <strong className={styles.black}>Fitness Pro</strong> on App Store
          </div> */}
          <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="/contact">
              <span>Contact us</span>
              <Icon name="arrow-right" size="10" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
