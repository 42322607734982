import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../../../components/Icon";

const options = [
  {
    title: "Main features",
    items: [
      {
        title: "Home Page",
        description:
          "The suggested products are fetched for each child category from a parent category. The products are displayed in a horizontal slider.",
      },
      {
        title: "Offers Banner",
        description:
          "In the home page, there is a banner that shows the current offers. Each offer is a link to a category or a product.",
      },
      {
        title: "Product List Item",
        description:
          "Each product item contains image, name, price, rating, discount, availability and shipping info.",
      },
      {
        title: "Products Listing",
        description:
          "Showing infinite-scroll paginated products with filters and sorting options.",
      },
      {
        title: "Products Search",
        description:
          "Searching for products by name, description, SKU, etc.",
      },
      {
        title: "Favorites",
        description:
          "Showing a list of all the products that the user added to the favorites list. The user will be able to add and remove products from that list.",
      },
      {
        title: "Dynamic Filters",
        description:
          "Showing all the filters based on the products available in the category.",
      },
      {
        title: "Dynamic Sorting Options",
        description:
          "Showing all the sorting options based on the products available in the category.",
      },
      {
        title: "Price Range Slider",
        description: "A fully functional price range slider.",
      },
      {
        title: "General Product Info",
        description:
          "Contains a gallery of images, product description, price, rating, discount, availability, shipping info, reviews, related products, add-to-cart button.",
      },
      {
        title: "Gallery",
        description:
          "A fully functional images gallery with gestures to zoom-in and zoom-out.",
      },
      {
        title: "Description",
        description:
          "A fully functional description section with HTML support to be able to support any kind of content in there.",
      },
      {
        title: "Variants Selector",
        description:
          "Showing all the variants available for the product, the options can be of type: color, size, etc.",
      },
      {
        title: "Reviews",
        description:
          "A horizontal slider of reviews, tap on a review to see it in full screen.",
      },
      {
        title: "Price",
        description:
          "Showing the price of the product with the discount if available. Showing the price per unit or per weight / volume if available.",
      },
      {
        title: "Categories",
        description:
          "Showing all the categories as a list of items with a horizontal slider. Each item is tapable to show the subcategories of the selected category.",
      },
      {
        title: "Cart",
        description:
          "Add and remove products from the cart. Change the quantity of each product in the cart. Move products to the favorites list.",
      },
      {
        title: "Guest Checkout",
        description:
          "A fully functional checkout process for guests, where the user can add his address, select a shipping method, select a payment method and place the order.",
      },
      {
        title: "Login & Register",
        description:
          "Login and register with email and password. You can also register as a guest without the need to enter a password.",
      },
      {
        title: "Addresses",
        description:
          "Add, edit and remove addresses from the profile. Mark them as default for shipping and/or billing.",
      },
      {
        title: "User Profile",
        description: "Edit the the name, payment methods, addresses and see orders history.",
      },
      {
        title: "Payment Methods",
        description: "Select a default payment method.",
      },
      {
        title: "Custom Logo",
        description: "We will add your logo and your custom colors to the app.",
      },
      {
        title: "Custom Colors",
        description: "Any color scheme is supported, easy to be changed. Try a few color schemes available in the demo app.",
      },
      {
        title: "PayPal",
        description:
          "Pay using PayPal checkout. All PayPal payment methods are supported.",
      },
      {
        title: "Stripe",
        description:
          "Pay using Stripe checkout. All Stripe payment methods are supported.",
      },
      {
        title: "Apple Pay",
        description: "Pay with one tap using Apple Pay.",
      },
      {
        title: "Google Pay",
        description: "Pay with one tap using Google Pay.",
      },
      {
        title: "Push Notifications",
        description:
          "Send push notifications to your users with your current offers. We can integrate with any push notification service.",
      },
      {
        title: "Universal Links",
        description:
          "Open the app using a link from the browser or from another app.",
      },
      {
        title: "SEO Friendly Links",
        description:
          "Open the app using SEO links, the app will open the correct page based on the link.",
      },
      {
        title: "Analytics",
        description: "Track your users and their activity in the app.",
      },
      // {
      //   title: "Map Address Picker",
      //   description: "Pick an address from the map.",
      // },
      {
        title: "Native Mobile App",
        description:
          "The app is built using native technologies, Swift for iOS, Kotlin for Android and React Native is used for the ~96% of the shared code between iOS and Android.",
      },
      {
        title: "App Store Submission",
        description: "We will submit the app to the App Store for you.",
      },
      {
        title: "Play Store Submission",
        description: "We will submit the app to the Play Store for you.",
      },
      {
        title: "App Store Account",
        description:
          "We will create an App Store account for you and upload the app.",
      },
      {
        title: "Play Store Account",
        description:
          "We will create a Play Store account for you and upload the app.",
      },
      {
        title: "Device Coverage",
        description:
          "The app supports any version that is equal or newer of iOS 13.2 and Android 6.0. This covers 95% of the iOS users and 93% of the Android users.",
      },
    ],
  },
];

const data = [
  {
    title: "iOS",
    color: "#000",
    // description: "14 days of free trial",
    // price: "0",
    note: "per month",
    button: "Start free trial",
    options: [
      "true",
      "true",
      "true",
      "true",
      "soon",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
      "soon",
      "true",
      "true",
      "soon",
      // "soon",
      "true",
      "true",
      "false",
      "true",
      "false",
      "true",
    ],
  },
  {
    title: "Android",
    color: "#000",
    // description: "Fit with everyone",
    price: "20.88",
    note: "per month",
    button: "Get Started",
    options: [
      "true",
      "true",
      "true",
      "true",
      "soon",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
      "true",
      "soon",
      "true",
      "true",
      "soon",
      // "soon",
      "true",
      "false",
      "true",
      "false",
      "true",
      "true",
    ],
  },
  {
    title: "Shopware",
    color: "#000",
    // description: "Are you pro? Let’s do it",
    button: "Contact Sale",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
      "false",
      "false",
      "false",
      "true",
      "false",
      "false",
      "false",
      "false",
      "false",
      "false",
      "false",
      "false",
    ],
  },
];

const Table = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>go mobile. sell more</div>
        <h1 className={cn("h1", styles.title)}>Features Info</h1>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.head}></div>
              <div className={styles.body}>
                {options.map((option, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.category}>{option.title}</div>
                    {option.items.map((item, index) => (
                      <div className={styles.parameter} key={index}>
                        <div className={styles.label}>{item.title}</div>
                        {item.description && (
                          <div className={styles.hint}>
                            <Icon name="info" size="10" />
                            <div className={styles.tooltip}>
                              {item.description}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            {data.map((type, index) => (
              <div className={styles.col} key={index}>
                <div className={styles.head}>
                  <div className={styles.package} style={{ color: type.color }}>
                    {type.title}
                  </div>
                  <div className={styles.description}>{type.description}</div>
                </div>
                <div className={styles.body}>
                  <div
                    className={cn(styles.more, {
                      [styles.active]: more[index],
                    })}
                    onClick={() => handleClick(index)}
                  >
                    See all features
                    <Icon name="arrow-bottom" size="9" />
                  </div>
                  <div
                    className={cn(styles.list, {
                      [styles.visible]: more[index],
                    })}
                  >
                    {options.map((option, optionIndex) => (
                      <div className={styles.item} key={optionIndex}>
                        {option.items.map((item, itemIndex) => (
                          <div className={styles.parameter} key={itemIndex}>
                            <div className={styles.label}>{item.title}</div>
                            {renderContent(type.options[itemIndex])}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={styles.text}>
            Backed by a team of mobile experts, you will get the most out of
            your Shopware store on mobile.
          </div>
          <button
            className={cn({ button: true }, styles.button)}
            onClick={() => {
              window.location.href = "/contact";
            }}
          >
            Contact Sales
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
