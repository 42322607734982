import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./History.module.sass";
import getBlogPosts from "../../../../data/blog/get-blog-posts";

const History = () => {
  const blogPosts = getBlogPosts()[0].items;
  
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <div className={styles.history}>
      <div className={styles.wrap}>
        <Slider className="history-slider" {...settings}>
          {blogPosts.map((x, index) => (
            <div className={styles.slide} key={index}>
              <div className={cn("history-item", styles.item)}>
                <div
                  className={styles.preview}
                  style={{ backgroundImage: `url(${x.image})` }}
                ></div>
                <div className={styles.details}>
                  <div
                    className={cn(
                      {
                        "status-pink": x.category === "pink",
                      },
                      {
                        "status-green": x.category === "green",
                      },
                      styles.status
                    )}
                  >
                    {x.categoryContent}
                  </div>
                  <div className={styles.title}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                  <Link
                    to={x.url}
                    className={cn("button-small", styles.button)}
                  >
                    Read full story
                  </Link>
                </div>
                <div className={styles.position}>{x.position}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default History;
