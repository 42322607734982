import React from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import DownloadDemoFrom from "../../../components/DownloadDemoForm";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <div className={styles.col}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/download-hero@2x.jpg 2x"
              src="/images/content/download-hero.jpg"
              alt="Download Hero"
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={cn("stage", styles.stage)}>time to get the app</div>
            <h1 className={cn("h1", styles.title)}>
              Store Demo app on iOS and Android
            </h1>
            <div className={styles.text}>
              <p>
                Get started with a free unlimited trial period and enjoy all the
                premium features inside the demo app.
              </p>
              <br/>
              <p>Everything you do in the app is in demo mode, including orders and payments, you won't be charged if you pay for something.</p>
            </div>

            <DownloadDemoFrom />
      
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
