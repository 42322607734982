import React from "react";
import cn from "classnames";
import styles from "./Post.module.sass";

const Post = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h1 className={cn("h1", styles.title)}>Privacy Policy</h1>
        </div>

        <h2>ARTICLE 13 GENERAL DATA PROTECTION REGULATION (GDPR)</h2>
        <br />
        <p>
          With this information sheet, GUU Labs SRL (hereinafter “Shop Mobile Pro“ or
          “we“) informs you about the processing of your personal data (“Data“)
          as well as your Data protection claims and rights:
        </p>
        <br />
        <h3>
          1 WHICH DATA ARE PROCESSED AND FROM WHICH SOURCES DO THEY COME FROM?
        </h3>
        <br />
        <p>
          We process the Data that we receive from you as part of your visit or
          purchase on our website www.shopmobilepro.com (hereinafter “Website“)
          or Webshop-App (hereinafter “Mobileshop”). We do not collect and
          process any special categories of personal Data.
        </p>
        <br />
        <p>Personal Data include:</p>
        <p>
          Your personal details: e.g. name, address, e-mail address, telephone
          number and gender Data about your Shop Mobile Pro purchases: e.g.
          customer number, UID number, previous purchases, invoice number,
          purchase date and time, product, quantity and price Data about your
          payment method: e.g. bank details, used credit card company.
        </p>
        <br />
        <h3>2 FOR WHAT PURPOSES AND FOR WHAT DURATION ARE DATA PROCESSED?</h3>
        <br />
        <p>
          We process your Data in accordance with applicable data protection law
          and for specific purposes and for a specific period. The most
          important purposes and duration of the processing are listed below. If
          we collect Data from you for other purposes, we will inform you
          separately before collecting that Data.
        </p>
        <br />
        <h3>2.1 PURCHASE AT SHOP MOBILE PRO</h3>
        <br />
        <p>
          We process the Data you provide when purchasing goods for the purpose
          of fulfilling our contractual obligations to you. This includes, for
          example, the delivery of goods ordered by you. We process this data
          until the fulfillment of our obligations; beyond that, only as long as
          there is a legal obligation to do so or we need Data for the exercise
          or defence of legal claims.
        </p>
        <br />
        <h3>2.2 SHOP MOBILE PRO CUSTOMER ACCOUNT</h3>
        <br />
        <p>
          We process the Data provided by you when creating your Shop Mobile Pro
          customer account for the provision of Shop Mobile Pro service offers.
          With your Shop Mobile Pro customer account you can process purchases
          faster, save more than one address, track your orders and much more.
          If you purchase goods online via your customer account, we also
          process your Data to perform and fulfill your purchase.
        </p>
        <br />
        <p>
          We will process the Data you provided for the Shop Mobile Pro customer
          account until you delete your customer account; beyond that, only as
          long as there is a legal obligation to do so or we need the Data for
          the exercise or defense of legal claims.
        </p>
        <br />
        <h3>2.3 NEWSLETTER</h3>
        <br />
        <p>
          We process the Data you provided when you signed up for the Shop
          Mobile Pro Newsletter for the purposes of direct marketing. We will
          send you personalized newsletters via e-mail and inform you about
          offers, services and events of GUU Labs SRL and our partner companies
          if, based on your Data, we assume that this information is
          particularly relevant and interesting for you. We process the Data you
          provided by signing up for the Shop Mobile Pro Newsletter as long as
          you wish to receive the newsletter and furthermore only as long as we
          need the Data for the exercise or defense of legal claims.
        </p>
        <br />
        <h3>2.4 SHOP MOBILE PRO CUSTOMER SERVICE</h3>
        <br />
        <p>
          When contacting our Customer Service („contact us“) for requests or
          issues we process your Data to respond to your requests or to fulfill
          your issues. We process the Data you provide only for the duration of
          the response or fulfillment of your requests and issues. Beyond that,
          we will process the Data only as long as there is a legal obligation
          to do so or we need the Data for the exercise or defense of legal
          claims.
        </p>
        <br />
        <h3>2.5 SHOP MOBILE PRO CHAT</h3>
        <br />
        <p>
          When you contact our Shop Mobile Pro Chat for requests or issues, and
          therefore provide us with your Data (name, e-mail address and
          message), these Data will be processes only to respond to your
          requests or fulfill your issues. The Data that you provide for the
          Shop Mobile Pro Chat will be processed for the duration of the
          response to fulfill your requests and issues. Beyond that, we will
          process the Data only as long as there is a legal obligation to do so
          or we need the Data for the exercise or defense of legal claims and
          for internal administrative purposes.
        </p>
        <br />
        <h3>3 ON WHAT LEGAL BASIS DO WE PROCESS YOUR DATA?</h3>
        <br />
        <h3>3.1 ON THE BASIS OF YOUR CONSENT (ARTICLE 6 (1) (A) GDPR):</h3>
        <br />
        <p>
          If you have given us your consent to the processing of your Data - for
          example to receive the Shop Mobile Pro Newsletter - this processing
          will only be carried out in accordance with the purposes specified in
          the respective declaration of consent and to the extent agreed
          therein.
        </p>
        <br />
        <p>
          You can withdraw your given consent at any time with effect for the
          future by sending us an e-mail or letter to our contact address as
          stated in point 9. The withdrawal of consent does not affect the
          lawfulness of the processing of your Data based on your consent before
          its withdrawal.
        </p>
        <br />
        <h3>
          3.2 TO FULFILL CONTRACTUAL OBLIGATIONS (ARTICLE 6 (1) (B) GDPR):
        </h3>
        <br />
        <p>
          We process your Data in order to fulfill our contractual obligations
          to you. For example, we need your name and address to send you ordered
          goods and issue an invoice for your order. If there are any delivery
          problems or if you have concerns or requests, we need, for example,
          your e-mail address or phone number in order to contact you.
        </p>
        <br />
        <h3>3.3 TO FULFILL LEGAL OBLIGATIONS (ARTICLE 6 (1) (C) GDPR):</h3>
        <br />
        <p>
          The processing of your Data may be required to fulfill our legal
          obligations (in particular for the storage of business papers and
          contractual documents).
        </p>
        <br />
        <h3>
          3.4 FOR THE PURPOSES OF THE LEGITIMATE INTERESTS (ARTICLE 6 (1) (F)
          GDPR):
        </h3>
        <br />
        <p>
          If it is necessary for the purposes of our legitimate interests or the
          legitimate interests of third parties, we process your Data:
        </p>
        <br />
        <p>
          Our legitimate interest in the processing your Data includes own and
          third-party marketing purposes, customer loyalty or direct marketing.
        </p>
        <br />
        <p>
          In addition, we have a legitimate interest in the processing of your
          Data for administrative purposes within Shop Mobile Pro and its
          affilliates and for the exercise or defense of legal claims.
        </p>
        <br />
        <h3>4 WHO RECEIVES YOUR DATA?</h3>
        <br />
        <p>
          Within Shop Mobile Pro and our affiliates, those employees will
          receive your personal information, who need them for the purposes
          outlined above. If we are legally obliged to do so, we will also
          transfer your Data to public bodies and authorities. In addition,
          companies commissioned by us (in particular IT or payment services and
          back office providers) will receive your Data if they need them to
          fulfill their respective tasks. These providers are obliged to treat
          all Data confidentially, to process it only to the extent necessary
          for their service provision and they provide their processing
          activities within the European Economic Area. If these companies
          provide their processing activities outside the European Economic
          Area, there are appropriate safeguards according to Art 46 GDPR in
          place to ensure an adequate level of data protection.
        </p>
        <br />
        <p>We will transfer your Data to the following recipients:</p>
        <br />
        <table>
          <tr>
            <th>Company Name</th>
            <th>Located in</th>
            <th>Safeguards</th>
          </tr>
          <tr>
            <td>Shopware AG</td>
            <td>Germany</td>
            <td></td>
          </tr>
          <tr>
            <td>Stripe, Inc.</td>
            <td>USA</td>
            <td>Standard Contractual Clauses</td>
          </tr>
          <tr>
            <td>PayPal (Europe) S.à.r.l. &amp; Cie</td>
            <td>Luxembourg</td>
            <td></td>
          </tr>
          <tr>
            <td>Klarna Bank AB</td>
            <td>Sweden </td>
            <td></td>
          </tr>
          <tr>
            <td>Falcon.io ApS</td>
            <td>Denmark</td>
            <td></td>
          </tr>
          <tr>
            <td>MailerLite Limited</td>
            <td>Ireland</td>
            <td>Standard Contractual Clauses</td>
          </tr>
          <tr>
            <td>MailerLite, Inc.</td>
            <td>USA</td>
            <td>Standard Contractual Clauses</td>
          </tr>
          <tr>
            <td>platform.sh</td>
            <td>Germany</td>
            <td></td>
          </tr>
        </table>
        <br />
        <h3>COOKIES</h3>
        <br />
        <br />
        <h3>5.1 TECHNICALLY REQUIRED COOKIES</h3>
        <br />
        <p>
          We use cookies on our Website, which are small files stored on your
          device (e.g. web browser or mobile device). On your next visit to our
          Website or Mobileshop using the same device, the information stored in
          cookies will subsequently be returned to us. Additionally, as you
          browse the Website, we collect information about the individual web
          pages or products that you view, what websites or search terms
          referred you to the Website, and information about how you interact
          with the Website or the Mobileshop. We use the Data collected through
          these cookies to better represent our Website and Mobileshop and to
          make our offers more user-friendly, for example to evaluate the use of
          our Website or Mobileshop. Some cookies remain stored on your device
          until you delete them. They allow us to recognize your browser on your
          next visit. Other cookies are only stored for the duration of your
          visit.
        </p>
        <br />
        <p>For collecting this Data we use the following technologies:</p>
        <br />
        <p>
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org.
        </p>
        <br />
        <p>
          “Log files” track actions occurring on the Website or Mobileshop, and
          collect data including your IP address, browser type, referring/exit
          pages, and date/time stamps. “Web beacons”, “tags”, and “pixels” are
          electronic files used to record information about how you browse the
          Website and use the Mobileshop.
        </p>
        <br />
        <p>WEBSITE:</p>
        <br />
        <p>Cookies Necessary for the Functioning of the Store:</p>
        <br />
        <table>
          <tr>
            <th>Cookie Name</th>
            <th>Purpose</th>
            <th>Storage period</th>
          </tr>
          <tr>
            <td>section_data_ids</td>
            <td>
              Facilitates the caching of content in the browser, so pages load
              faster.
            </td>
            <td>Until the end of the page visit</td>
          </tr>
          <tr>
            <td>PHPSESSID</td>
            <td>Your session ID on the server.</td>
            <td>1 hour</td>
          </tr>
          <tr>
            <td>form_key</td>
            <td>
              Stores randomly generated keys to prevent the use of forged
              information.
            </td>
            <td>1 hour</td>
          </tr>
          <tr>
            <td>Pnctest</td>
            <td>Tests whether cookies are supported by your browser.</td>
            <td>1 hour</td>
          </tr>
          <tr>
            <td>session-</td>
            <td>Unique identifier for your session.</td>
            <td>Until the end of the page visit.</td>
          </tr>
          <tr>
            <td>sw-states</td>
            <td>
              This cookie describes the current session in simple tags like
              `cart-filled` and `logged-in`
            </td>
            <td>Until the end of the page visit.</td>
          </tr>
          <tr>
            <td>sw-cache-hash</td>
            <td>This cookie contains the active rules and active currency.</td>
            <td>Until the end of the page visit.</td>
          </tr>
          <tr>
            <td>timezone</td>
            <td>Timezone of the user.</td>
            <td>End of the current day.</td>
          </tr>
        </table>
        <br />
        <p>
          All these cookies are technically necessary for the presentation of
          the Website. You can deactivate the setting of cookies in the settings
          of your browser. Please note that a general deactivation of cookies
          may possibly lead to functional limitations of our Website.
        </p>
        <br />
        <p>MOBILESHOP:</p>
        <br />
        <table>
          <tr>
            <th>Cookie Name</th>
            <th>Purpose</th>
            <th>Storage period</th>
          </tr>
          <tr>
            <td>SSID</td>
            <td>Done through Facebook and Google listed below.</td>
            <td>Until you close the app.</td>
          </tr>
        </table>
        <br />
        <p>Cookies Necessary for the Functioning of the Store:</p>
        <p>
          All these cookies are technically necessary for the presentation of
          the Mobileshop.
        </p>
        <br />
        <p>
          Additionally, we use pixels and tags from the following third parties
          (which may in turn place cookies):
        </p>
        <br />
        <table>
          <tr>
            <th>Third Party</th>
            <th>Description</th>
            <th>Privacy Policy</th>
          </tr>
          <tr>
            <td>Google Analytics</td>
            <td>
              We use Google Analytics to help measure how users interact with
              our websites and mobile apps. For example, we analyze the number
              of visitors to our websites, how we are found, and which products
              are most frequently viewed. This information is always used in an
              anonymous, aggregated form. We do not use it to identify
              individual users or their personal details.
            </td>
            <td>https://policies.google.com/privacy</td>
          </tr>
          <tr>
            <td>Google Ads</td>
            <td>
              We use Google Ads to deliver targeted advertisements to
              individuals who visit our websites and mobile apps.
            </td>
            <td>https://policies.google.com/privacy</td>
          </tr>
          <tr>
            <td>Facebook Ads</td>
            <td>
              We use Facebook Custom Audiences to deliver targeted
              advertisements to individuals who visit our websites and mobile
              apps.
            </td>
            <td>https://www.facebook.com/policy.php</td>
          </tr>
          <tr>
            <td>Pinterest</td>
            <td>
              We use Pinterest Custom Audiences to deliver targeted
              advertisements to individuals who visit our websites and mobile
              apps.
            </td>
            <td>
              https://policy.pinterest.com/en-gb/privacy-policy#section-residents-of-the-eea
            </td>
          </tr>
          <tr>
            <td>Snapchat</td>
            <td>
              We use Snapchat Custom Audiences to deliver targeted
              advertisements to individuals who visit our websites and mobile
              apps.
            </td>
            <td>https://www.snap.com/en-GB/privacy/privacy-policy</td>
          </tr>
          <tr>
            <td>TikTok</td>
            <td>
              We use TikTok Custom Audiences to deliver targeted advertisements
              to individuals who visit our websites and mobile apps.
            </td>
            <td>https://www.tiktok.com/legal/privacy-policy?lang=en</td>
          </tr>
          <tr>
            <td>LinkedIn</td>
            <td>
              We use LinkedIn Custom Audiences to deliver targeted
              advertisements to individuals who visit our websites and mobile
              apps.
            </td>
            <td>https://www.linkedin.com/legal/privacy-policy</td>
          </tr>
          <tr>
            <td>X / Twitter</td>
            <td>
              We use Twitter Custom Audiences to deliver targeted advertisements
              to individuals who visit our websites and mobile apps.
            </td>
            <td>https://twitter.com/en/privacy</td>
          </tr>
          <tr>
            <td>Google Tag Manager</td>
            <td>
              We use Google Tag Manager to manage website tags via a single
              interface.
            </td>
            <td>https://policies.google.com/privacy</td>
          </tr>
          <tr>
            <td>Google Optimize</td>
            <td>
              We use Google Optimize to test different versions of our websites
              and mobile apps.
            </td>
            <td>https://policies.google.com/privacy</td>
          </tr>
          <tr>
            <td>Google Firebase</td>
            <td>
              We use Google Firebase to help measure how users interact with our
              mobile apps.
            </td>
            <td>https://policies.google.com/privacy</td>
          </tr>
          <tr>
            <td>PayPal</td>
            <td>
              We use PayPal as one of our payment providers to process your
              orders and capture payments.
            </td>
            <td>https://www.paypal.com/en/webapps/mpp/ua/privacy-full</td>
          </tr>
          <tr>
            <td>Stripe</td>
            <td>
              We use Stripe as one of our payment providers to process your
              orders and capture payments.
            </td>
            <td>https://stripe.com/gb/privacy</td>
          </tr>
        </table>
        <br />
        <h3>5.2 GOOGLE ANALYTICS</h3>
        <br />
        <p>
          Furthermore, we use cookies of Google Analytics, a web analysis
          service provided by Google LLC (hereinafter “Google”). These cookies
          transmit data about your usage of the website to a Google server in
          the USA. However, your IP address will be shortened by Google prior to
          transmission and the transmitted data can no longer be associated with
          your person. Google will use this information to evaluate general
          usage data of our website and to compile reports on website
          activities. If you want to prevent the use of Google Analytics cookies
          on the website, you can either do this through your browser settings
          (see point 5.1), or you can install the browser plug-in available
          under the following link: http://tools.google.com/dlpage/gaoptout.
        </p>
        <br />
        <p>
          For information about how Google and its affiliates use data and
          storage practices, please visit Google's Privacy Policy, currently
          available at: https://www.google.com/privacy.html.
        </p>
        <br />
        <table>
          <tr>
            <th>Google Analytics Cookie</th>
            <th>Purpose</th>
            <th>Storage period</th>
          </tr>
          <tr>
            <td>_gat</td>
            <td>
              Used to throttle request rate. If Google Analytics is deployed via
              Google Tag Manager, this cookie will be named _dc_gtm_.
            </td>
            <td>1 minute</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>Used to distinguish users, store and count pageviews.</td>
            <td>24 hours</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>Used to distinguish users and store anonymized statistics.</td>
            <td>2 years</td>
          </tr>
        </table>
        <br />
        <h3>5.3 FACEBOOK AUDIENCE PIXEL</h3>
        <br />
        <p>
          We also use the Facebook Audience Pixel analysis tool from Facebook
          Ireland Limited or Facebook Inc. to measure the effectiveness of our
          advertising. The pixel collects information about website and mobile
          app usage, such as when the website or app are used and whether goods
          are placed in the shopping basket, and transmits this information to
          Facebook’s servers in Ireland and the United States. This information
          may also be cross-checked with other Facebook information or our
          information that we have about you. All data collected by this pixel
          is encrypted by Facebook using “hashes”. Facebook Ireland Limited is
          located in the European Union; Facebook Inc. is located in the United
          States and has a Privacy Shield Certificate which ensures the
          protection of your data.
        </p>
        <br />
        <p>
          The collection of data by Facebook Pixel only takes place with your
          consent. This consent can be withdrawn by you at any time. The
          comparison of the data with the data stored by us is based on our
          legitimate interest in marketing and customer loyalty.
        </p>
        <br />
        <h3>5.4 SNAPCHAT PIXEL</h3>
        <br />
        <p>
          Our website uses the "Snapchat Pixel" provided by Snap Inc, 63 Market
          Street, Venice, CA 90291, USA ("Snapchat"). This service allows us to
          track users' behaviour after they have seen or clicked on a Snapchat
          ad and have been redirected to our site. This process is used to
          evaluate the effectiveness of Snapchat ads for statistical and market
          research purposes and can help us to optimise our advertising efforts.
          The IP address, pixel ID or web page domain may also be transmitted to
          Snapchat to enable optimised measurement of the advertising campaigns.
          This data is stored and processed by Snapchat so enabling a connection
          to the respective user profile and allowing Snapchat to use the data
          for its own advertising purposes, in accordance with Snapchat's
          Privacy Policy. Whenever Snapchat transfers data of EU users outside
          the EU, Snapchat makes sure an adequate transfer mechanism is in
          place.
        </p>
        <br />
        <h3>5.5 TIKTOK PIXEL</h3>
        <br />
        <p>
          We use the "TikTok pixel" on this website which is provided by TikTok
          (for EU: TikTok Information Technologies UK Limited, Aviation House,
          125 Kingsway Holborn, London, WC2B 6NH.). We have implemented this
          code on our website. The code establishes a connection with the TikTok
          servers when users visit our website in order to track the behaviour
          of users on our website. This process is used to evaluate the
          effectiveness of Tiktok advertisements for statistical and market
          research purposes and may help us to optimise our advertising efforts.
          Personal data such as the IP address, as well as other information
          such as device ID, device type and operating system may also be
          transferred to TikTok to enable optimised targeting of advertising
          campaigns. TikTok processes this data to identify users of our website
          and associate their actions with a TikTok user account. TikTok
          processes this data to display targeted and personalised advertising
          to its users.
        </p>
        <br />
        <p>
          TikTok’s Privacy Policy can be accessed here. Where TikTok transfers
          personal data to countries outside the EEA, TikTok does so under the
          European Commission’s model contracts for the transfer of personal
          data to third countries (i.e. standard contractual clauses) pursuant
          to Commission Decision 2004/915/EC or 2010/87/EU (as appropriate) or
          in line with any replacement mechanism approved under EU law.
        </p>
        <br />
        <h3>5.6 PINTERST TAG</h3>
        <br />
        <p>
          Our website uses the conversion tracking technology of the social
          network Pinterest (Pinterest Europe Ltd., Palmerston House, 2nd Floor,
          Fenian Street, Dublin 2, Ireland), which enables us to display
          relevant advertisements and offers on Pinterest to our website
          visitors who have already taken an interest in our website and our
          content/offers and are Pinterest members. For this purpose, a
          so-called conversion tracking pixel from Pinterest is integrated on
          our pages, via which Pinterest is informed when a user visits our
          website that they have called up our website and in which parts of our
          offer they are interested. This procedure is used to evaluate the
          effectiveness of the Pinterest ads for statistical and market research
          purposes and can help to optimise our advertising measures. Personal
          data such as the IP address, as well as other information such as the
          device ID, device type and operating system may also be transferred to
          Pinterest in order to enable optimised measurement of the advertising
          campaigns. Because Pinterest is a worldwide service, Pinterest may
          transfer the personal data of EEA residents to a country outside the
          EEA. When Pinterest transfers information from the EEA to a country
          that doesn't provide an adequate level of protection, Pinterest will
          only do so under appropriate safeguards, such as standard contractual
          clauses. Pinterest's Privacy Policy can be accessed here.
        </p>
        <br />
        <h3>5.7 LINKEDIN INSIGHT TAG</h3>
        <br />
        <p>
          Our website makes use of function "LinkedIn Insight Tag" of the
          LinkedIn network. The provider is the LinkedIn Corporation, 2029
          Stierlin Court, Mountain View, CA 94043, USA. Each time one of our
          pages containing LinkedIn functions is accessed, a connection to
          LinkedIn servers is established. LinkedIn receives the information
          (containing your IP-address) that you have visited our web pages. If
          you click the LinkedIn "Recommend button" and are logged into your
          account at LinkedIn, LinkedIn will be able to assign the information
          that you have visited our website to your user account. As the
          provider of this website, we would like to point out that we have no
          information on what data is transmitted or how this transmitted data
          is used by LinkedIn. By using LinkedIn Insight Tag, we can analyze the
          success of our campaigns on LinkedIn. LinkedIn is certified under the
          Privacy Shield agreement.
          (https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active).
          For years LinkedIn has relied on overlapping protections under both
          Standard Contractual Clauses (SCCs) and the Privacy Shield legal
          frameworks for data transfers. While the ruling by the European Court
          of Justice of the July 16, 2020 invalidated the use of Privacy Shield,
          SCCs remain in place and LinkedIn continues to transfer data from the
          EU, EEA and Switzerland using SCCs. LinkedIn is also monitoring
          ongoing negotiations between the U.S. Department of Commerce and EU
          Commission regarding a Privacy Shield replacement. Despite its
          invalidation as a transfer mechanism, LinkedIn has elected to maintain
          its Privacy Shield certification from the U.S. Department of Commerce.
        </p>
        <br />
        <p>
          Privacy Policy: https://www.linkedin.com/legal/privacy-policy,
          Opt-Out:
          https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
        </p>
        <br />
        <h3>6 ARE YOU OBLIGED TO PROVIDE DATA?</h3>
        <br />
        <p>
          To receive a service - a purchase, customer account or newsletter - it
          is necessary that you provide the Data we need to fulfill our
          contractual obligations to you and to perform our voluntary
          performances and services. Those Data are marked with (*) as
          mandatory. Unless you provide those mandatory Data, we will generally
          be unable to provide our services.
        </p>
        <br />
        <h3>7 YOUR RIGHTS IN THE CONTEXT OF THE PROCESSING OF YOUR DATA</h3>
        <br />
        <p>You have the right:</p>
        <br />
        <p>
          - To request information about the processing of your Data (right of
          access);
        </p>
        <br />
        <p>
          - To request the correction of incorrect or incomplete Data (right to
          rectification);
        </p>
        <br />
        <p>- To restrict the processing of your Data (Article 18 GDPR); </p>
        <br />
        <p>- To withdraw your consent (Article 7 GDPR);</p>
        <br />
        <p>- To object to the processing of your Data (Article 21 GDPR);</p>
        <br />
        <p>- To Data portability (Article 20 GDPR).</p>
        <br />
        <p>
          If you believe that we violate your rights under the GDPR or national
          data protection law when processing your Data, please contact us. This
          is the best way we can treat your concerns as quickly as possible. Of
          course, you also have the right to lodge a complaint with the
          responsible data protection supervisory authority.
        </p>
        <br />
        <h3>
        8 AUTOMATED DECISION-MAKING
        </h3>
        <br />
        <p>We do not use automated decision-making or profiling according to Article 22 GDPR.</p>
        <br />
        <h3>9 WHO CAN YOU CONTACT?</h3>
        <br />
        <p>
          For questions regarding the processing of your Data, please contact
          our data protection officer at the following address:
        </p>
        <br />
        <p>
          GUU Labs SRL
          <br />
          E-Mail: hey@guulabs.com
        </p>
      </div>
    </div>
  );
};

export default Post;
