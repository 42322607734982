import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
// import Item from "./Item";
import TextItem from "./TextItem";
import Dropdown from "../../components/Dropdown";

const items = [
  {
    title: "General",
    items: [
      {
        title: "How does the free trial work?",
        response:
          "The demo mobile apps are there to provide a solid foundation for you to see how things work. We will customize the mobile apps with your logo and colors. The free trial has no time limit, allowing you to use it indefinitely.",
      },
      {
        title: "Who is the owner of the mobile app?",
        response:
          "You are the owner of the mobile app. We will publish the mobile app under your own App Store and Google Play accounts.",
      },
      {
        title: "Who is making the updates to the mobile app?",
        response:
          "We will make security and maitenance updates to the mobile app. You don't need to do anything. We will also make sure that the mobile app is compatible with the latest versions of iOS and Android.",
      },
      {
        title: "Who is making sure the mobile app is working?",
        response:
          "We are responsible for the mobile app. We will make sure that the mobile app is working as expected, we will fix any issues that might appear. The only thing you need to do is to make sure that your store is working.",
      },
      {
        title:
          "I don't have a App Store / Google Play account, can you help me?",
        response:
          "Yes, we can help you with the process of creating an App Store and Google Play account. Apple charges $99 per year and Google charges $25 one time fee.",
      },
      {
        title: "How long does it take to publish the mobile app?",
        response:
          "The minimum duration is 2-3 days and the maximum duration is 1-2 weeks. It depends on the complexity of the app and the time it takes to get the app approved by Apple and Google.",
      },
      {
        title: "Can you publish the mobile app under my own account?",
        response:
          "Yes, we can publish the mobile app under your own App Store and Google Play accounts.",
      },
      {
        title: "What's the minimum supported version of iOS and Android?",
        response:
          "The minimum supported version is iOS 13.2 and Android 6.0. This covers 95% of the iOS users and 93% of the Android users.",
      },
      {
        title: "I never used Push Notifications, how does it work?",
        response:
          "Push Notifications are messages that are sent directly to your users' devices. You can send them manually from the dashboard or automatically when you publish a new product or a new offer. You can also send them automatically when a user adds a product to the cart and doesn't complete the order.",
      },
      {
        title:
          "If I make a change in my iOS app, do I need to do the same on Android?",
        response:
          "No, you don't need to make the same change twice. 90% of the code is shared between iOS and Android. This means that if you make a change in your iOS app, the same change will be applied to your Android app. This increases development speed and saves you a lot of time and money.",
      },
      {
        title: "Do you offer mobile design services?",
        response:
          "Yes, we offer mobile design services. We will help you with the design of your mobile app.",
      },
      {
        title: "Do you offer custom mobile development?",
        response:
          "Any mobile related feature that you need can be developed by us, we're a full service agency. We can also help you with the development of any other mobile app that you have on iOS and Android.",
      },
    ],
  },
  {
    title: "Shopware",
    items: [
      {
        title: "How does the Shopware integration work?",
        response:
          "The Shopware integration is based on the Shopware's Store API. The only thing you need to do is to create a new headless sales channel. The mobile app uses the Shopware Store API to get the data from your store. Anything that you change in your store will be automatically updated in the mobile app. Anything that is supported by the Store API is supported by the mobile app.",
      },
      {
        title: "I want a custom Shopware integration, can you help me?",
        response: "Yes, we can help you with a custom Shopware integration.",
      },
      {
        title: "What's the minimum supported version of Shopware?",
        response:
          "The minimum supported version is Shopware 6, but we can also support Shopware 5 with a bit of adjustment.",
      },
      {
        title: "What payment methods are supported?",
        response:
          "The mobile app supports all the payment methods that are supported by Shopware.",
      }
    ],
  },
];

const Team = () => {
  const options = [];
  items.map((x) => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section-border-top", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={cn("stage-small", styles.stage)}>
           The more I learn, the more questions I have
          </div>
          <h2 className={cn("h2", styles.title)}>Frequently Asked Questions</h2>
          <div className={styles.info}>
            Learn more about our services. If you don't find an answer feel
            free to <a href="/contact">contact us</a> and we will get back to you.
          </div>
          <div className={styles.nav}>
            {items.map((x, index) => (
              <button
                className={cn(styles.btn, {
                  [styles.active]: x.title === category,
                })}
                onClick={() => setCategory(x.title)}
                key={index}
              >
                {x.title}
              </button>
            ))}
          </div>
          <Dropdown
            className={styles.dropdown}
            value={category}
            setValue={setCategory}
            options={options}
          />
        </div>
        <div className={styles.list}>
          {items
            .find((x) => x.title === category)
            .items.map((x, index) => (
              <TextItem item={x} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
