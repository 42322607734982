import React, { useState } from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import History from "./History";
import Item from "./Item";
import ScrollParallax from "../../../components/ScrollParallax";
import getBlogPosts from "../../../data/blog/get-blog-posts";

const Hero = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const blogPosts = getBlogPosts();

  
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <h2 className={cn("hero", styles.title)}>Blog</h2>
        <div className={styles.info}>
          See the latest articles from our blog and stay up to date with the
          latest e-commerce trends on mobile.
        </div>
        <History />
        <div className={styles.nav}>
          {blogPosts.map((x, index) => (
            <button
              className={cn(styles.btn, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.list}>
          {blogPosts[activeIndex].items.map((x, index) => (
            <ScrollParallax className={styles.box} key={index}>
              <Item item={x} className={styles.item} />
            </ScrollParallax>
          ))}
        </div>
        {/* <div className={styles.btns}>
          <button className={cn("button-stroke button-small", styles.button)}>
            Load more
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Hero;
