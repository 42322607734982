import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Hero.module.sass";
import Icon from "../../../components/Icon";

const status = [
  {
    status: "blue",
    statusContent: "QA",
  },
];

const photos = [
  {
    image: "/images/content/mobile-role-big-2.jpg",
    image2x: "/images/content/mobile-role-big-2@2x.jpg",
  },
];

const items = [
  {
    title: "Start date",
    content: "Q1 2024",
    icon: "calendar",
    size: "16",
  },
  {
    title: "Salary",
    content: "$$",
    icon: "clock",
    size: "16",
  },
  {
    title: "Experience",
    content: "Entry - Mid",
    color: "#45B26B",
    icon: "scoreboard",
    size: "20",
  },
  {
    title: "Flexibility",
    content: "Full-time",
    icon: "candlesticks",
    size: "18",
  },
];

const list = [
  "We understand the importance of work-life balance and offer flexible work hours to accommodate your needs.",
  "Opportunities for continuous learning, attending conferences, and gaining certifications.",
  "Be a part of a dynamic and collaborative team that values innovation and creativity.",
];

const Hero = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.details}>
              {status.map((x, index) => (
                <div
                  className={cn(
                    { "status-red": x.status === "red" },
                    { "status-blue": x.status === "blue" },
                    {
                      "status-green": x.status === "green",
                    },
                    styles.status
                  )}
                  key={index}
                >
                  {x.statusContent}
                </div>
              ))}
            </div>
            <h2 className={cn("h2", styles.title)}>Manual QA</h2>
            <div className={styles.info}>
              Be ready to work with a team of professionals.
            </div>
            <div className={styles.wrap}>
              <Slider className="workout-slider" {...settings}>
                {photos.map((x, index) => (
                  <div className={styles.slide} key={index}>
                    <div className={cn("workout-item", styles.item)}>
                      <div className={styles.preview}>
                        <img
                          src={x.image}
                          srcSet={`${x.image2x} 2x`}
                          alt="Workout"
                        />
                        {x.play && (
                          <button className={cn("play", styles.play)}>
                            <Icon name="play" size="21" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.group}>
              {items.map((x, index) => (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size={x.size} />
                  </div>
                  <div className={styles.parameter}>{x.title}</div>
                  <div className={styles.content} style={{ color: x.color }}>
                    {x.content}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.text}>
              <p>
                Collaborate with the development team to understand project
                requirements and develop test plans and test cases accordingly.
              </p>
              <p>
                Manually execute test cases to identify defects, bugs, and
                inconsistencies in software applications.
              </p>
              <p>
                Document and report defects and issues found during testing
                using established reporting processes, including clear and
                concise bug reports.
              </p>
              <p>
                Conduct regression testing to ensure that issues are fixed and
                that new changes do not introduce new problems.
              </p>
              <p>
                Test software on various platforms, browsers, and devices to
                ensure cross-platform compatibility.
              </p>

              <p>Benefits:</p>
            </div>
            <div className={styles.list}>
              {list.map((x, index) => (
                <div className={styles.box} key={index}>
                  {x}
                </div>
              ))}
            </div>
            <div className={styles.btns}>
              <div />
              <button
                onClick={() => {
                  window.location.href = "/contact";
                }}
                className={cn("button", styles.button)}
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
