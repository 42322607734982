import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Story.module.sass";
import ScrollParallax from "../ScrollParallax";

const items = [
  {
    title: "How it works",
    content:
      "We build, deliver and maintain your mobile app for you. You don't have to worry about anything.",
  },
  {
    title: "Ready to be integrated",
    content:
      "No need to wait for months to develop an app. We already have a solution that can be integrated with your Shopware store.",
  },
];

const ArticleAppPromo = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage-small", styles.stage)}>our solution</div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.preview}>
              <img src="/images/content/article-promo-app.png" alt="Mobile App Screenshot" />
            </div>
          </div>
          <div className={styles.col}>
            <h2 className={cn("h2", styles.title)}>Store mobile apps built for you</h2>
            <div className={styles.info}>
              Our mobile apps are the best way to get the most out of your Shopware store. Have you own mobile store app packed with all the features from your current store.
            </div>
            <div className={styles.list}>
              {items.map((x, index) => (
                <ScrollParallax className={styles.item} key={index}>
                  <div className={styles.category}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </ScrollParallax>
              ))}
            </div>
            <Link className={cn("button-stroke", styles.button)} to="/download">
              See the demo
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleAppPromo;
