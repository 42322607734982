const staticBlogs = [
  {
    title: "News",
    items: [
      {
        id: "5f803157-58ca-47fe-9713-6062858a6393",
        url: "/blog/eu-digital-markets-act-and-apples-pwa-impact-on-e-commerce",
        title: "Impact of EU DSA and iOS 17.4 on Shopware PWA",
        content:
          "The discontinuation of support for progressive web apps (PWAs) in the EU by Apple with the iOS 17.4 update has significantly impacted Shopware stores and EU merchants. This article delves into the effects of this decision on Shopware PWAs and their user experience.",
        avatar: "/images/content/cristian-gutu-avatar.jpg",
        author: "Cristian Gutu",
        image: "/images/content/eu-pwa-ios.jpg",
        image2x: "/images/content/eu-pwa-ios.jpg",
        category: "pink",
        date: "Feb 23, 2024",
        timestamp: 1708691923000,
        categoryContent: "news",
      },
    ],
  },
  {
    title: "Guides",
    items: [
      {
        id: "a5194f30-fe3b-4de1-b44c-56aa16fab4e7",
        url: "/blog/how-to-connect-your-shopware-store-to-the-mobile-app",
        title: "How to connect your Shopware store to the mobile app",
        content:
          "Find out how easy it is to connect your Shopware store to the mobile app. In this guide, we will show you how to do it step by step.",
        avatar: "/images/content/cristian-gutu-avatar.jpg",
        author: "Cristian Gutu",
        image: "/images/content/connect-shopware-to-mobile.jpg",
        image2x: "/images/content/connect-shopware-to-mobile.jpg",
        category: "green",
        date: "Feb 25, 2024",
        timestamp: 1708864723000,
        categoryContent: "guide",
      },
    ],
  },
];

const getBlogPosts = () => {
  return [
    { title: "All", items: staticBlogs.flatMap((x) => x.items).sort((a, b) => b.timestamp - a.timestamp)},
    ...staticBlogs,
  ];
};

export default getBlogPosts;
