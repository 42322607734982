import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styles from "./Lifestyle.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Home and Product Listing",
    image: "/images/content/shopware-mobile-home.jpg",
    image2x: "/images/content/shopware-mobile-home@2x.jpg",
    content:
      "Every product from your Shopware store is automatically synced with the mobile app.",
  },
  {
    title: "Product Details",
    image: "/images/content/shopware-mobile-product-details.jpg",
    image2x: "/images/content/shopware-mobile-product-details@2x.jpg",
    content:
      "All the product details are available in the app, including gallery, reviews, product variants and cross selling listings.",
  },
  {
    title: "Sort and Filters",
    image: "/images/content/shopware-mobile-sort-filter.jpg",
    image2x: "/images/content/shopware-mobile-sort-filter@2x.jpg",
    content:
      "Users can sort and filter products by price, name, rating, color, size, etc. Every option is dynamically showed based on the current product listing.",
  },
  {
    title: "Categories",
    image: "/images/content/shopware-mobile-categories.jpg",
    image2x: "/images/content/shopware-mobile-categories@2x.jpg",
    content:
      "All the categories and sub-categories from your Shopware store are automatically synced with the mobile app.",
  },
  {
    title: "More",
    image: "/images/content/shopware-mobile-more.jpg",
    image2x: "/images/content/shopware-mobile-more@2x.jpg",
    content:
      "The mobile app includes features like Favorites, Cart, Checkout, Profile, Guest Checkout, Login, Register, My Addresses, Online Payments, Shipping, Order History, SEO URLs, etc.",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Lifestyle = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}>explore the app</div>
          <h2 className={cn("h2", styles.title)}>Features</h2>
          <div className={styles.info}>
            All the essentials for your Shopware are ready. You simply
            add your unique touch to personalize it further.{" "}
          </div>
          <Link
            className={cn("button-stroke", styles.button)}
            to="/download"
          >
            <span>Install Demo App</span>
            <Icon name="arrow-right" size="10" />
          </Link>
        </div>
        <div className={styles.wrap}>
          <Slider
            className={cn("lifestyle-slider", styles.slider)}
            {...settings}
          >
            {items.map((x, index) => (
              <ScrollParallax className={styles.item} key={index}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.details}>
                      {/* <div className={styles.number}>0{index + 1}.</div> */}
                      <div className={styles.number}>{x.title}</div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <img
                      srcSet={`${x.image2x} 2x`}
                      src={x.image}
                      alt="Lifestyle"
                    />
                  </div>
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Lifestyle;
