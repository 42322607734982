import React from "react";
import { BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";
import { MAIN_URL } from "./consts";


import App from "./App";
import { Helmet } from "react-helmet";

const socialInfo = {
  title: "Shop Mobile Pro - iOS and Android apps for Shopware 6",
  content:
    "Mobile apps on iOS and Android for Shopware 6 stores. Ready to be integrated with your store.",
  keywords:
    "e-commerce, shopware, pwa, mobile app, mobile store, ios, android, ios store, android store, shopware ios, shopware android",
  author: "Shop Mobile Pro",
  image: `${MAIN_URL}/fb-og-image.jpg`,
  twitterImage: `${MAIN_URL}/twitter-card.jpg`,
  url: MAIN_URL
};

const AppWrapper = () => (
  <React.StrictMode>
    <Helmet>
      <title>{socialInfo.title}</title>
      <meta name="description" content={socialInfo.content} />
      <meta name="keywords" content={socialInfo.keywords} />
      <meta name="author" content={socialInfo.author} />
      <meta property="og:title" content={socialInfo.title} />
      <meta property="og:description" content={socialInfo.content} />
      <meta property="og:image" content={socialInfo.image} />
      <meta property="og:url" content={socialInfo.url} />
      <meta name="twitter:title" content={socialInfo.title} />
      <meta name="twitter:description" content={socialInfo.content} />
      <meta name="twitter:image" content={socialInfo.twitterImage} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@ShopMobilePro25" />
      <meta name="twitter:creator" content="@ShopMobilePro25" />
    </Helmet>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<AppWrapper />, rootElement);
} else {
  render(<AppWrapper />, rootElement);
}
