import React from "react";
import cn from "classnames";
import { Helmet } from "react-helmet";

import styles from "./Post.module.sass";
import getBlogPosts from "../../../data/blog/get-blog-posts";
import { MAIN_URL } from "../../../consts";
//import Icon from "../../../components/Icon";


const Post = ({ id }) => {
  const post = getBlogPosts().flatMap((x) => x.items).find((x) => x.id === id);
  
  return (
    <>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.content} />
        <meta name="keywords" content="e-commerce, shopware, pwa, ios 17.4, apple, EU, digital markets act, mobile app, ios, android" />
        <meta name="author" content={post.author} />
        <meta property="og:title" content={post.title} />
        <meta
          property="og:description"
          content={post.content}
        />
        <meta property="og:image" content={post.image} />
        <meta property="og:url" content={post.url} />
        <meta name="twitter:title" content={post.title} />
        <meta
          name="twitter:description"
          content={post.content} 
        />
        <meta name="twitter:image" content={`${MAIN_URL}${post.image}`} />
      </Helmet>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.head}>
            <h1 className={cn("h1", styles.title)}>
             {post.title}
            </h1>
            {/* <button className={cn("button-circle-stroke", styles.button)}>
            <Icon name="download" size="22" />
          </button> */}
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.preview}>
                <img src="/images/content/eu-pwa-ios.jpg" alt="EU Flag" />
              </div>
            </div>
            <div className={styles.col}>
              {/* <h2 className={cn("h2", styles.title)}>As easy as hitting play</h2> */}
              <div className={styles.info}>
               {post.content}
              </div>
              <div className={styles.preview}>
                <img
                  src="/images/content/eu-pwa-ios-post-pic.jpg"
                  alt="Safari App Icon"
                />
              </div>
              <div className={styles.content}>
                <h2 className={cn("h2", styles.title)}>
                  What is Shopware PWA?
                </h2>
                <p>
                  Progressive web apps built on the Shopware eCommerce platform
                  deliver an app-like experience by merging website and native
                  app functionalities for a seamless UX/UI. Shopware PWAs boast
                  fast loading times, an offline mode, and push notifications.
                </p>
                <h2 className={cn("h2", styles.title)}>
                  Impact of the EU Digital Services Act
                </h2>
                <p>
                  The EU Digital Services Act (DSA) compels Apple to allow
                  alternative browsers and app stores on iOS devices. As a
                  countermeasure, Apple has ended support for PWAs on iOS in the
                  EU with the iOS 17.4 update. This regulatory shift impacts the
                  functionality of Shopware PWAs for European Union merchants.
                  The lack of PWA support means EU merchants can no longer
                  utilize PWA features to boost user engagement.
                </p>
                <p>
                  These limitations include:
                  <li>
                    No Push Notifications: Merchants cannot send real-time
                    notifications to users.
                  </li>
                  <li>
                    No "Add to Home Screen" Option: It's not possible for users
                    to add the PWA to their home screen anymore.
                  </li>
                  <li>
                    Reduced Offline Functionality: The removal of PWA support
                    limits the offline capabilities of Shopware PWAs.
                  </li>
                  <li>
                    Reduced User Engagement: The lack of PWA support could lead
                    to a decrease in user engagement.
                  </li>
                </p>
                <p>
                  Apple contends that integrating alternative browser engines
                  would necessitate substantial architectural changes, deemed
                  impractical due to the DMA's other requirements and the
                  minimal adoption of PWAs.
                </p>
                <h2 className={cn("h2", styles.title)}>
                  The Future of Shopware PWAs in the EU
                </h2>
                <p>
                  Shopware PWAs in the EU will likely face challenges in
                  maintaining user engagement and delivering a seamless user
                  experience. Merchants may need to consider alternative
                  solutions to compensate for the lack of PWA support on iOS
                  devices.
                </p>
                <p>
                  Because of the discontinuation of PWA support, Shopware
                  merchants in the EU should consider the following
                  alternatives:
                  <li>
                    Native Mobile Apps: Merchants can develop native mobile apps
                    to provide users with an app-like experience.
                  </li>
                  <li>
                    Hybrid Apps: Merchants can build hybrid apps that combine
                    native and web app functionalities.
                  </li>
                </p>

                <h2 className={cn("h2", styles.title)}>Conclusion</h2>
                <p>
                  The discontinuation of PWA support on iOS devices in the EU
                  has significantly impacted Shopware PWAs and their user
                  experience. Merchants should consider alternative solutions to
                  maintain user engagement and deliver a seamless user
                  experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;
