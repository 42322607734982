import React from "react";
import cn from "classnames";
import { Helmet } from "react-helmet";

import styles from "./Post.module.sass";
import getBlogPosts from "../../../data/blog/get-blog-posts";
import { MAIN_URL } from "../../../consts";
//import Icon from "../../../components/Icon";

const Post = ({ id }) => {
  const post = getBlogPosts()
    .flatMap((x) => x.items)
    .find((x) => x.id === id);

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.content} />
        <meta
          name="keywords"
          content="shopware, headless channel, sales channel, mobile, store, ios, android"
        />
        <meta name="author" content={post.author} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.content} />
        <meta property="og:image" content={post.image} />
        <meta property="og:url" content={post.url} />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.content} />
        <meta name="twitter:image" content={`${MAIN_URL}${post.image}`} />
      </Helmet>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.head}>
            <h1 className={cn("h1", styles.title)}>{post.title}</h1>
            {/* <button className={cn("button-circle-stroke", styles.button)}>
            <Icon name="download" size="22" />
          </button> */}
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.preview}>
                <img src={post.image} alt="Two connected chairs" />
              </div>
            </div>
            <div className={styles.col}>
              {/* <h2 className={cn("h2", styles.title)}>As easy as hitting play</h2> */}
              <div className={styles.info}>{post.content}</div>
              <div className={styles.preview}>
                <img
                  src="/images/content/shopware-mobile-home@2x.jpg"
                  alt="Shopware products list and the mobile app on a smartphone"
                />
              </div>
              <div className={styles.content}>
                <h2 className={cn("h2", styles.title)}>How does it work?</h2>
                <p>
                  Shopware offers the ability to create multiple sales channels.
                  Sales channels allow you to operate multiple, separate stores
                  from a single Shopware instance. These stores can have
                  different configurations, themes, and products.
                </p>

                <p>
                  For example, you can create a sales channel for your main
                  store, and another sales channel for a separate store that is
                  will be used in the mobile app.
                </p>
                <h2 className={cn("h2", styles.title)}>
                  How to create a new sales channel?
                </h2>
                <p>
                  Go Shopware Administration page, on the right, search for
                  Sales channel section. Click on the plus symbol to create a
                  new sales channel.
                </p>
                <div className={styles.preview}>
                  <img
                    src="/images/content/connect-shopware-1.png"
                    alt="Shopware Administration page"
                  />
                </div>
                <p>
                  A popup will appear, select a headless channel. This a sales
                  channel that is not directly accessible by customers. It is
                  used to provide data to other frontends, such as a mobile app.
                </p>
                <div className={styles.preview}>
                  <img
                    src="/images/content/connect-shopware-2.png"
                    alt="Shopware Administration page"
                  />
                </div>

                <p>
                  Fill out the channel name and other settings, for our case you
                  should have the same settings like your Storefront web
                  channel. Check the{" "}
                  <a
                    href="https://docs.shopware.com/en/shopware-6-en/settings/saleschannel#:~:text=Overview,edited%20by%20clicking%20on%20them."
                    target="_blank"
                    rel="noreferrer"
                  >
                    official Shopare docs page
                  </a>{" "}
                  for more info about each field.
                </p>
                <div className={styles.preview}>
                  <img
                    src="/images/content/connect-shopware-3.jpg"
                    alt="New channel settings"
                  />
                </div>

                <p>
                  Now the most important part is the "API access" section. This
                  API access key will be used in the mobile app to connect to
                  your Shopware store. Each sales channel has its own API access
                  key.
                </p>

                <p>
                  Make sure to toggle the status to "active" and save the
                  changes.
                </p>

                <div className={styles.preview}>
                  <img
                    src="/images/content/connect-shopware-4.png"
                    alt="API access settings"
                  />
                </div>
                <h2 className={cn("h2", styles.title)}>Done</h2>
                <p>
                  Once the sales channel is created, you can privately share the API access key with the mobile app developers. They will use this key to connect the mobile app to your Shopware store.
                </p>

                <p>🎉 That's it! You have successfully connected your Shopware store to the mobile app.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;
