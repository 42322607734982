import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";

const Hero = ({ scrollToRef }) => {
    return (
        <div className={styles.hero}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrap}>
                    <div className={cn("stage", styles.stage)}>
                        go mobile. sell more
                    </div>
                    <h1 className={cn("h1", styles.title)}>
                    iOS and Android Apps for Shopware Stores.
                    </h1>
                    <div className={styles.text}>
                       Integrate your Shopware store with our mobile solution, personalized for you, and start selling more today.
                    </div>
                    <div className={styles.btns}>
                        <Link
                            className={cn("button", styles.button)}
                            to="/download"
                        >
                            Install Demo App
                        </Link>
                        <Link
                            className={cn("button-stroke", styles.button)}
                            to="/features"
                        >
                            App Features
                        </Link>
                    </div>
                </div>
                <ScrollButton
                    onScroll={() =>
                        scrollToRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                    className={styles.scroll}
                />
                <div className={styles.gallery}>
                    <div className={styles.preview}>
                        <Image
                            srcSet="/images/content/app-home-and-favorites@2x.jpg 2x"
                            src="/images/content/app-home-and-favorites.jpg"
                            alt="App Screens Home Checkout"
                        />
                    </div>
                    {/* <div className={styles.preview}>
                        <img
                            srcSet="/images/content/shop-front@2x.png 2x"
                            src="/images/content/shop-front.png"
                            alt="Shop Front"
                        />
                    </div>
                    <div className={styles.preview}>
                        <img
                            srcSet="/images/content/delivery-truck@2x.png 2x"
                            src="/images/content/delivery-truck.png"
                            alt="Delivery truck"
                        />
                    </div>
                    <div className={styles.preview}>
                        <img
                            srcSet="/images/content/shopping-cart@2x.png 2x"
                            src="/images/content/shopping-cart.png"
                            alt="Shopping cart"
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Hero;
