import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Features from "./screens/Features";
import Pricing from "./screens/Pricing";
import Download from "./screens/Download";
import Class01 from "./screens/Class01";
import Class01Details from "./screens/Class01Details";
import Class02 from "./screens/Class02";
import Class02Details from "./screens/Class02Details";
import Lifestyle from "./screens/Lifestyle";
import Article from "./screens-articles/Article";
import Careers from "./screens/Careers";
import MobileSeniorRoleDetails from "./screens/MobileSeniorRoleDetails";
import ManualQAEntryDetails from "./screens/EntryManualQA";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsOfService from "./screens/TermsOfService";
import Faq from "./screens/Faq";
import Contact from "./screens/Contact";
import Blog from "./screens/Blog";
import ArticlePwaiOS from "./screens-articles/ArticlePwaiOS";
import ArticleConnectShopware from "./screens-articles/ArticleConnectShopware";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route
          path="features"
          element={
            <Page>
              <Features />
            </Page>
          }
        />
        <Route
          path="pricing"
          element={
            <Page>
              <Pricing />
            </Page>
          }
        />
        <Route
          path="careers"
          element={
            <Page>
              <Careers />
            </Page>
          }
        />
        <Route
          path="download"
          element={
            <Page>
              <Download />
            </Page>
          }
        />
        <Route
          path="faq"
          element={
            <Page>
              <Faq />
            </Page>
          }
        />
        <Route
          path="blog"
          element={
            <Page>
              <Blog />
            </Page>
          }
        />
        <Route
          path="privacy-policy"
          element={
            <Page>
              <PrivacyPolicy />
            </Page>
          }
        />
        <Route
          path="terms-of-service"
          element={
            <Page>
              <TermsOfService />
            </Page>
          }
        />
        <Route
          path="contact"
          element={
            <Page>
              <Contact />
            </Page>
          }
        />
        <Route
          path="class01"
          element={
            <Page>
              <Class01 />
            </Page>
          }
        />
        <Route
          path="class01-details"
          element={
            <Page>
              <Class01Details />
            </Page>
          }
        />
        <Route
          path="mobile-senior-details"
          element={
            <Page>
              <MobileSeniorRoleDetails />
            </Page>
          }
        />
        <Route
          path="manual-entry-qa-details"
          element={
            <Page>
              <ManualQAEntryDetails />
            </Page>
          }
        />

        <Route
          path="class02"
          element={
            <Page>
              <Class02 />
            </Page>
          }
        />
        <Route
          path="class02-details"
          element={
            <Page>
              <Class02Details />
            </Page>
          }
        />
        <Route
          path="lifestyle"
          element={
            <Page>
              <Lifestyle />
            </Page>
          }
        />
        <Route
          path="article"
          element={
            <Page>
              <Article />
            </Page>
          }
        />
        <Route
          path="blog/eu-digital-markets-act-and-apples-pwa-impact-on-e-commerce"
          element={
            <Page>
              <ArticlePwaiOS />
            </Page>
          }
        />

        <Route
          path="blog/how-to-connect-your-shopware-store-to-the-mobile-app"
          element={
            <Page>
              <ArticleConnectShopware />
            </Page>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
