import React from "react";
import Post from "./Post";
import ArticleAppPromo from "../../components/ArticleAppPromo";

const Article = () => {
    return (
        <>
            <Post id={'5f803157-58ca-47fe-9713-6062858a6393'} />
            <ArticleAppPromo />
        </>
    );
};

export default Article;
