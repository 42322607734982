import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";

import cn from "classnames";
import styles from "./ContactForm.module.sass";
import Icon from "../Icon";

const ContactForm = ({ className, placeholder }) => {
  const [state, handleSubmit] = useForm("mjvnwpdy");

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [message, setMessage] = useState("");

  if (state.succeeded) {
    return (
      <p>
        <Icon name="check" size="14" /> All set! We will get back to you in the
        next 1-2 business days.
      </p>
    );
  }

  return (
    <form
      className={cn(styles.form, className)}
      action=""
      onSubmit={handleSubmit}
    >
      <input
        className={styles.input}
        type="text"
        autoComplete="name"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
        name="fullName"
        placeholder={"Full Name *"}
        required
      />
      <input
        className={styles.input}
        type="email"
        autoComplete="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        name="email"
        placeholder={"Enter your email *"}
        required
      />
      <textarea
        className={styles.inputArea}
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        name="message"
        placeholder={"Your message * "}
        required
      />

      <ValidationError prefix="Message" field="message" errors={state.errors} />

      <button
        type="submit"
        className={cn({ button: true }, styles.button)}
        disabled={state.submitting}
      >
        {state.submitting ? "Sending..." : "Send"}{" "}
        {state.submitting ? "" : <Icon name="arrow-next" size="14" />}
      </button>
    </form>
  );
};

export default ContactForm;
