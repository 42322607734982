import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";

const options = [
  {
    title: "Main features",
    items: [
      {
        title: "iOS and Android apps",
        description: "No webviews, no hybrid apps, no compromises.",
      },
      {
        title: "Synced with Shopware",
        description:
          "All products, categories, orders and customers are synced with your Shopware store.",
      },
      {
        title: "Unlimited Products",
      },
      {
        title: "Unlimited Users",
      },
      {
        title: "Unlimited Push Notifications",
        description:
          "Send push notifications to your customers with offers and news.",
      },
      {
        title: "Custom Colors",
      },
      {
        title: "Deep Linking",
        description:
          "Redirect links from emails, ads, and blogs directly to the product or product listing page",
      },
      {
        title: "Multiple Payment Methods",
        description:
          "Pay for products with different payment methods. We support any payment method that is supported by Shopware.",
      },
      {
        title: "Multi Languages",
      },
      {
        title: "Multi Currencies",
      },
      {
        title: "Advanced Themes",
        description: "Includes custom colors, fonts, icons and more.",
      },
      {
        title: "Custom Layouts",
      },
      {
        title: "Advanced Integrations",
        description:
          "Integrate the mobile apps with your favorite tools and services.",
      },
    ],
  },
];

const data = [
  {
    title: "Free Trial",
    color: "rgb(57, 177, 89)",
    description: "Unilimited free trial in the demo store app.",
    price: "0",
    note: "per month",
    button: "Start Free Trial",
    buttonNav: "/download",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
      "false",
      "false",
      "false",
      "false",
    ],
  },
  {
    title: "Premium",
    color: "#5338DC ",
    description: "The best value for growing stores.",
    price: "1500",
    note: "per month",
    button: "Get Started",
    buttonNav: "/contact",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
      "false",
      "false",
      "false",
      "false",
    ],
  },
  {
    title: "Pro",
    color: "#EF466F",
    price: "",
    note: "per month",
    description: "Custom features for your store.",
    button: "Contact Sales",
    buttonNav: "/contact",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
    ],
  },
];

const Plan = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          sell more with a mobile app
        </div>
        <h1 className={cn("h1", styles.title)}>
          Choose the plan that fits your needs
        </h1>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.head}></div>
              <div className={styles.body}>
                {options.map((option, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.category}>{option.title}</div>
                    {option.items.map((item, index) => (
                      <div className={styles.parameter} key={index}>
                        <div className={styles.label}>{item.title}</div>
                        {item.description && (
                          <div className={styles.hint}>
                            <Icon name="info" size="10" />
                            <div className={styles.tooltip}>
                              {item.description}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            {data.map((type, index) => (
              <div className={styles.col} key={index}>
                <div className={styles.head}>
                  <div className={styles.package} style={{ color: type.color }}>
                    {type.title}
                  </div>
                  <div className={styles.description}>{type.description}</div>

                  {type.price ? (
                    <>
                      <div className={styles.cost}>
                        <span className={styles.sign}>€</span>{" "}
                        <span className={styles.price}>{type.price}</span>
                      </div>
                      <div className={styles.note}>{type.note}</div>
                    </>
                  ) : (
                    <div className={styles.costEmpty}></div>
                  )}
                </div>
                <div className={styles.body}>
                  <div
                    className={cn(styles.more, {
                      [styles.active]: more[index],
                    })}
                    onClick={() => handleClick(index)}
                  >
                    See all features
                    <Icon name="arrow-bottom" size="9" />
                  </div>
                  <div
                    className={cn(styles.list, {
                      [styles.visible]: more[index],
                    })}
                  >
                    {options.map((option, optionIndex) => (
                      <div className={styles.item} key={optionIndex}>
                        {option.items.map((item, itemIndex) => (
                          <div className={styles.parameter} key={itemIndex}>
                            <div className={styles.label}>{item.title}</div>
                            {renderContent(type.options[itemIndex])}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <Link
                    to={type.buttonNav}
                    className={cn(
                      { button: index === 0 },
                      { button: index === 1 },
                      { "button-stroke": index === 2 },
                      styles.button
                    )}
                  >
                    {type.button}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
