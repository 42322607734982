import React from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import CardJob from "../../../components/CardJob";

const items = [
  {
    title: "Mobile Developer",
    url: "/mobile-senior-details",
    image: "/images/content/mobile-role-1.jpg",
    image2x: "/images/content/mobile-role-1@2x.jpg",
    category: "red",
    categoryText: "Mobile",
    avatar: "/images/content/cristian-gutu-avatar.jpg",
    trainer: "React Native Developer with strong iOS/Android skills.",
    level: "red",
    levelText: "Senior",
  },
  {
    title: "Manual QA",
    url: "/manual-entry-qa-details",
    image: "/images/content/mobile-role-2.jpg",
    image2x: "/images/content/mobile-role-2@2x.jpg",
    category: "blue",
    categoryText: "QA",
    avatar: "/images/content/cristian-gutu-avatar.jpg",
    trainer: "Be ready to work with a team of professionals.",
    level: "green",
    levelText: "Entry - Mid",
  },
];

const Catalog = () => {

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={styles.title}>Career Opportunities</div>
        </div>
       
        <div className={styles.list}>
          {items.map((x, index) => (
            <CardJob className={styles.card} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Catalog;
