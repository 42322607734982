import React from "react";
import CareersCatalog from "./CareersCatalog";

const Careers = () => {
  return (
    <>
      <CareersCatalog />
    </>
  );
};

export default Careers;
