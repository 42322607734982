import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Hero.module.sass";
import Icon from "../../../components/Icon";

const status = [
  {
    status: "red",
    statusContent: "mobile",
  },
];

const photos = [
  {
    image: "/images/content/mobile-role-big-1.jpg",
    image2x: "/images/content/mobile-role-big-1@2x.jpg",
  },
];

const items = [
  {
    title: "Start date",
    content: "Q1 2024",
    icon: "calendar",
    size: "16",
  },
  {
    title: "Salary",
    content: "$$$$",
    icon: "clock",
    size: "16",
  },
  {
    title: "Experience",
    content: "Senior",
    color: "#5338DC",
    icon: "scoreboard",
    size: "20",
  },
  {
    title: "Flexibility",
    content: "Full-time",
    icon: "candlesticks",
    size: "18",
  },
];

const list = [
  "We understand the importance of work-life balance and offer flexible work hours to accommodate your needs.",
  "Opportunities for continuous learning, attending conferences, and gaining certifications.",
  "Be a part of a dynamic and collaborative team that values innovation and creativity.",
];

const Hero = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.details}>
              {status.map((x, index) => (
                <div
                  className={cn(
                    { "status-red": x.status === "red" },
                    {
                      "status-green": x.status === "green",
                    },
                    styles.status
                  )}
                  key={index}
                >
                  {x.statusContent}
                </div>
              ))}
            </div>
            <h2 className={cn("h2", styles.title)}>Mobile Developer</h2>
            <div className={styles.info}>
              React Native Developer with strong iOS & Android skills.
            </div>
            <div className={styles.wrap}>
              <Slider className="workout-slider" {...settings}>
                {photos.map((x, index) => (
                  <div className={styles.slide} key={index}>
                    <div className={cn("workout-item", styles.item)}>
                      <div className={styles.preview}>
                        <img
                          src={x.image}
                          srcSet={`${x.image2x} 2x`}
                          alt="Workout"
                        />
                        {x.play && (
                          <button className={cn("play", styles.play)}>
                            <Icon name="play" size="21" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.group}>
              {items.map((x, index) => (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size={x.size} />
                  </div>
                  <div className={styles.parameter}>{x.title}</div>
                  <div className={styles.content} style={{ color: x.color }}>
                    {x.content}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.text}>
              <p>
                Experience with native modules and bridging in React Native is a
                must.
              </p>
              <p>
                Proficiency in iOS and Android development, with a deep
                understanding of the platform-specific guidelines, APIs, and
                best practices.
              </p>
              <p>
                Strong TypeScript and ES6+ knowledge. Familiarity with mobile
                app architecture patterns.
              </p>
              <p>
                Experience with third-party libraries and APIs integration.
                Solid understanding of Git and version control.
              </p>
              <p>
                Knowledge of mobile app performance optimization techniques.
              </p>
              <p>
                Excellent problem-solving and debugging skills. Strong
                communication and collaboration skills, as you will work closely
                with design, product, and backend teams.
              </p>
              <p>Benefits:</p>
            </div>
            <div className={styles.list}>
              {list.map((x, index) => (
                <div className={styles.box} key={index}>
                  {x}
                </div>
              ))}
            </div>
            <div className={styles.btns}>
              <div />
              <button
                onClick={() => {
                  window.location.href = "/contact";
                }}
                className={cn("button", styles.button)}
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
