import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import useQuery from "../../hooks/UseQuery";

const Page = ({ children }) => {
    const { pathname } = useLocation();
    const query = useQuery();
    const inMobileApp = query.get("inMobileApp");

    useEffect(() => {
        window.scrollTo(0, 0);
        clearAllBodyScrollLocks();
    }, [pathname]);

    return (
        <div className={styles.page}>
            {!inMobileApp && <Header />}
            <div className={styles.inner}>{children}</div>
            <Footer />
        </div>
    );
};

export default Page;
