import React from "react";
import Post from "./Post";
//import Review from "../../components/Review";
import ArticleAppPromo from "../../components/ArticleAppPromo";

const Article = () => {
    return (
        <>
            <Post id={'a5194f30-fe3b-4de1-b44c-56aa16fab4e7'} />
            <ArticleAppPromo />
        </>
    );
};

export default Article;
