import React, { useState } from "react";
import cn from "classnames";
import styles from "./DownloadDemoForm.module.sass";
import Icon from "../Icon";
import { MAIN_URL } from "../../consts";

const DownloadDemoFrom = ({ className, placeholder }) => {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState("");

  const [fetchState, setFetchState] = useState("idle");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = new URL(
      `${MAIN_URL}/.netlify/functions/addDemoRequest`
    );
    url.searchParams.append("email", email);
    url.searchParams.append("fullName", encodeURI(fullName));
    if (websiteUrl) {
      url.searchParams.append("websiteUrl", encodeURI(websiteUrl));
    }
    if (howDidYouHearAboutUs) {
      url.searchParams.append(
        "howDidYouHearAboutUs",
        encodeURI(howDidYouHearAboutUs)
      );
    }
    setFetchState("loading");
    try {
      await fetch(url);
    } finally {
      setFetchState("finished");
    }
    setFetchState("finished");
    setEmail("");
    setFullName("");
    setWebsiteUrl("");
    setHowDidYouHearAboutUs("");
  };

  if (fetchState === "finished") {
    return (
      <p>
        <Icon name="check" size="14" /> The demo request was sent! To install the demo app, please
        check your email.
      </p>
    );
  }

  return (
    <form
      className={cn(styles.form, className)}
      action=""
      onSubmit={handleSubmit}
    >
      <input
        className={styles.input}
        type="text"
        autoComplete="name"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
        name="fullName"
        placeholder={"Full Name*"}
        required
      />
      <input
        className={styles.input}
        type="email"
        autoComplete="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        name="email"
        placeholder={"Enter your email*"}
        required
      />
      <input
        className={styles.input}
        type="text"
        autocapitalize="none"
        value={websiteUrl}
        onChange={(e) => setWebsiteUrl(e.target.value)}
        name="websiteUrl"
        placeholder={"Your website URL"}
        required
      />
      <input
        className={styles.input}
        type="text"
        value={howDidYouHearAboutUs}
        onChange={(e) => setHowDidYouHearAboutUs(e.target.value)}
        name="howDidYouHearAboutUs"
        placeholder={"How did you hear about us?"}
      />

      <button className={cn({ button: true }, styles.button)}>
        {fetchState === "loading" ? "Sending..." : "Send demo"}{" "}
        {fetchState === "loading" ? "" : <Icon name="arrow-next" size="14" />}
      </button>
    </form>
  );
};

export default DownloadDemoFrom;
